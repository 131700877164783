import { get, isEmpty } from "lodash";
import dynamic from "next/dynamic";
import Head from "next/head";
import { Router } from "next/router";
import { useStoreState } from "pullstate";
import { useEffect } from "react";
import queryString from "query-string";
import Store from "../utils/Store";
import { QueryClient, QueryClientProvider } from "react-query";
import Script from "next/script";
import { useIdleTimer } from "react-idle-timer";
import Alert from "../components/Alert";
import Logout from "../components/Logout";
import Menubar from "../components/Menubar";
import ExportDataModal from "../components/ExportData/ExportDataModal";
import { PatientsContextProvider } from "../components/Patients/PatientsContext";
import { GatewayContextProvider } from "../components/GatewayIndicator/GatewayContext";
import Toast from "../components/Toast";
import NetworkError from "../components/NetworkError";
import Authenticator from "../components/common/Authenticator";
import ExportMessage from "../components/ExportMessage";
import RNEnvSelector from "../components/RNEnvSelector";
import ChangeRegionModal from "../components/ChangeRegionModal/Index";
import DatadogProvider from "../components/DatadogProvider";
import NotificationSubscription from "../components/NotificationSubscription";
import SettingsModal from "../components/Settings/SettingsModal";
import "../i18n";
import "../styles/globals.css";
import constants from "../utils/constants";
import { getIsOnpremise, isAndroidWebView, isIOSWebView, isWebView } from "../utils";
import "react-image-crop/dist/ReactCrop.css";
import useCheckWorklistContainer from "../hooks/useCheckWorklistContainer";
import useCheckLCContainer from "../hooks/useCheckLCContainer";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

const isOnpremise = getIsOnpremise();

const ProductFruits =
  !isOnpremise &&
  dynamic(() => import("react-product-fruits").then((mod) => mod.ProductFruits), {
    ssr: false,
  });
Router.events.on("routeChangeComplete", () => {
  window?.productFruits &&
    window?.productFruits?.pageChanged &&
    window?.productFruits?.pageChanged();
});

const PRODUCT_FRUIT_TOKEN = process.env.NEXT_PUBLIC_PRODUCTFRUITS_WORKSPACE_CODE;

function MyApp({ Component, pageProps }) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  });
  const { t } = useTranslation();
  const {
    user,
    authData,
    hasLicenseExpiredForSelectedPatient,
    patientsFileDeletionStatus,
  } = useStoreState(Store, (store) => ({
    user: store.user,
    authData: store.userInfo,
    hasLicenseExpiredForSelectedPatient: store.hasLicenseExpiredForSelectedPatient,
    patientsFileDeletionStatus: store.patientsFileDeletionStatus,
  }));
  const userInfo = { username: get(user, ["preferred_username"], null) };
  const diskUsage = get(authData, ["disk_usage"], {});

  const { hideMenu, hasShare } = pageProps;
  const onIdle = () => {
    // Reloading the page when screen is idle after 7.5 Mins
    location.reload();
  };

  let timeout = 1000 * 60 * 7.5;
  if (isWebView()) {
    timeout = 1000 * 60 * 3;
  }
  const idleTimer = useIdleTimer({ onIdle, timeout });
  const isWorkList = useCheckWorklistContainer();
  const isLCWorklist = useCheckLCContainer();

  const { isDarkMode, isRNTestBuild } = useStoreState(Store, (s) => ({
    isDarkMode: s.isDarkMode,
    isRNTestBuild: s.isRNTestBuild,
  }));

  useEffect(() => {
    if (window) {
      const params = queryString.parse(window?.location?.search);
      if (params["redirectLogin"]) {
        localStorage.setItem("redirectLogin", true);
      }

      let _isDarkMode = JSON.parse(localStorage.getItem(constants.PERSISTED.isDarkMode));
      // to set default dark mode
      if (_isDarkMode === null) {
        _isDarkMode = true;
      }
      Store.update((s) => {
        s.isDarkMode = _isDarkMode;
      });
    }
  }, []);

  useEffect(() => {
    if (isDarkMode) {
      document.querySelector("body").classList.add("dark");
    } else {
      document.querySelector("body").classList.remove("dark");
    }
  }, [isDarkMode]);

  useEffect(() => {
    // Using web workers to pass data from Android and iOS webviews
    if (isIOSWebView() || isAndroidWebView()) {
      window.addEventListener("message", onRNMessage); // for iOS
      document.addEventListener("message", onRNMessage); // for Android
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({
          event: constants.RN_COMM_CONST.APP_READY,
        })
      );
    }

    return () => {
      window.removeEventListener("message", onRNMessage);
      document.removeEventListener("message", onRNMessage);
    };
  }, []);

  const onRNMessage = (e) => {
    const nativeData = get(e, ["data"], "");
    if (nativeData) {
      try {
        const messageData = JSON.parse(nativeData);
        Store.update((store) => {
          store.isRNTestBuild = get(messageData, ["isRNTestBuild"], store.isRNTestBuild);
          store.RNSelectedEnv = get(messageData, ["env"], store.RNSelectedEnv);
          store.RNfcmToken = get(messageData, ["fcmToken"], store.RNfcmToken);
          store.isRNAppStateChange = get(messageData, ["isRNAppStateChange"], false);
          store.RNAppVersion = get(messageData, ["AppVersion"], store.RNAppVersion);
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    // Check the screen width
    const screenWidth = window.innerWidth;

    if (screenWidth > 550) {
      window.onUsersnapCXLoad = function (api) {
        api.init({
          user: {
            app: "qure",
          },
        });
      };
    }

    // Dynamically create a script element
    const scriptElement = document.createElement("script");

    if (screenWidth > 550) {
      scriptElement.defer = 1;
      scriptElement.src =
        "https://widget.usersnap.com/global/load/1fec3ff9-9d2e-4d2e-a6b9-74cabf1f548e?onload=onUsersnapCXLoad";
    }
    // Append the script to the document head
    document.getElementsByTagName("head")[0].appendChild(scriptElement);

    // Clean up: remove the script element when the component unmounts
    return () => {
      document.getElementsByTagName("head")[0].removeChild(scriptElement);
    };
  }, []);

  const isDiskWarningRange =
    diskUsage && diskUsage.used_percent >= 90 && diskUsage.used_percent <= 99;

  const isDeletionStatus = (status) => ["in_process", "started"].includes(status);
  const isFilesDeletionOn =
    isDeletionStatus(diskUsage?.file_deletion) ||
    isDeletionStatus(patientsFileDeletionStatus);

  const onClickDiskAlert = () => {
    Store.update((s) => {
      s.isSettingsOpen = true;
      s.selectedSetting = "disk-management";
    });
  };

  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <meta property="og:site_name" content="Qure.ai" />
        <meta name="theme-color" content={isWorkList ? "#29333E" : "#0C1723"} />

        <meta
          name="description"
          content="View imaging studies and collaborate in real-time"
        />
        <meta
          property="og:description"
          content="View imaging studies and collaborate in real-time"
        />

        <meta property="og:image:width" content="1070" />
        <meta property="og:image:height" content="580" />
        <meta property="og:type" content="website" />

        <meta name="keywords" content="qure, qer, qxr, stroke" />
        <meta name="author" content="Qure.ai" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no"
        />
      </Head>
      {hasShare ? (
        <Head>
          <meta property="og:title" content="Study shared with you via Qure App" />
          <meta
            property="og:image"
            itemProp="image"
            content="https://app.qure.ai/assets/images/sharingPreview.png"
          />
        </Head>
      ) : (
        <Head>
          <meta property="og:title" content="Qure.ai" />
          <meta
            property="og:image"
            itemProp="image"
            content="https://app.qure.ai/assets/images/pageMeta.png"
          />
        </Head>
      )}
      <Script async src="https://www.googletagmanager.com/gtag/js?id=G-JPCJ0V0E2R" />
      <Script id="gtag">
        {`
          window.dataLayer = window.dataLayer || []
          function gtag(){dataLayer.push(arguments)}
          gtag('js', new Date());

          gtag('config', 'G-JPCJ0V0E2R');
        `}
      </Script>

      <DatadogProvider user={user} authData={authData}>
        <QueryClientProvider client={queryClient}>
          <div
            className={classNames(
              "h-full flex bg-gray-100 dark:bg-gray-900 sm:bg-gray-100 sm:dark:bg-gray-900",
              {
                "border-teal-500 border-2 border-t-0 rounded-b-[10px]": isLCWorklist,
              }
            )}
          >
            <Authenticator>
              {!isOnpremise &&
                global.window &&
                !isEmpty(user) &&
                !!PRODUCT_FRUIT_TOKEN && (
                  <ProductFruits
                    workspaceCode={PRODUCT_FRUIT_TOKEN}
                    language="en"
                    user={userInfo}
                    config={{ disableLocationChangeDetection: true }}
                  />
                )}
              {!isOnpremise && <NotificationSubscription />}
              <PatientsContextProvider>
                <GatewayContextProvider>
                  <div className="flex-1 min-w-0 flex flex-col w-full h-full overflow-hidden">
                    {!hideMenu && <Menubar />}
                    {isOnpremise &&
                      (isFilesDeletionOn ? (
                        <Alert
                          messageKey="disk_management.deletion_message"
                          showProgress={true}
                        />
                      ) : diskUsage?.used_percent > 99 ? (
                        <Alert
                          className="bg-red-600"
                          messageKey="disk_management.popup_message_critical"
                        />
                      ) : isDiskWarningRange ? (
                        <Alert
                          className="bg-yellow-400"
                          onClick={onClickDiskAlert}
                          messageKey="disk_management.popup_message_warning"
                          dynamicMessagePart={{
                            usedPercent: diskUsage?.used_percent,
                            totalDisk: diskUsage?.total,
                          }}
                        />
                      ) : null)}
                    {isOnpremise && hasLicenseExpiredForSelectedPatient && (
                      <Alert
                        className="bg-red-600"
                        messageKey="license_expire.popup_message"
                      />
                    )}
                    <Component {...pageProps} />
                  </div>
                  <ChangeRegionModal />
                  <ExportDataModal />
                  <SettingsModal />
                </GatewayContextProvider>
              </PatientsContextProvider>
            </Authenticator>
            <Logout />
            <Toast />
            {!isOnpremise && <NetworkError />}
            <ExportMessage />
            {!isOnpremise && isRNTestBuild && <RNEnvSelector />}
          </div>
        </QueryClientProvider>
      </DatadogProvider>
    </>
  );
}

export default MyApp;
